import * as React from 'react';
import { Link, graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import { IProps } from '../interfaces/category';
import { IContentFulProject } from '../interfaces/project';

export default class CategoryTemplate extends React.Component<IProps, object> {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  render() {
    const category = this.props.data.contentfulCategory;
    const { title, projects, icon } = category;
    return (
      <div>
        <h1>{title}</h1>
        <img src={icon.file.url} />
        <div>
          <span>Projects</span>
          <ul>
            {projects &&
              projects.map((p: IContentFulProject) => (
                <li key={p.id}>
                  <Link to={`/projects/${p.id}`}>{p.projectTitle}</Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

export const pageQuery = graphql`
  query categoryQuery($id: String!) {
    contentfulCategory(id: { eq: $id }) {
      title
      icon {
        file {
          url
          fileName
        }
      }
      projects {
        id
        projectTitle
        projectDescription {
          projectDescription
        }
      }
    }
  }
`;
